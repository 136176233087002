<template>
  <header>
    <div class="logo">
      <img src="../assets/logo.svg" alt="logo" />
      <svg class="logo-bkg" width="286" height="82" viewBox="0 0 286 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.39098e-06 82H270.203C274.795 82 278.517 77.5228 278.517 72V10C278.517 4.47716 281.843 7.62939e-06 286 7.62939e-06H0C0 7.62939e-06 7.39098e-06 4.47716 7.39098e-06 10V82Z"
          fill="#C4C4C4"
          fill-opacity="0.75"
        />
      </svg>
      <svg class="logo-bkg-short" width="286" height="52" viewBox="0 0 286 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.39098e-06 52H270.203C274.795 52 278.517 49.1608 278.517 45.6585V6.34146C278.517 2.83917 281.843 6.65828e-07 286 6.65828e-07H0C0 6.65828e-07 7.39098e-06 2.83917 7.39098e-06 6.34146V52Z"
          fill="#C4C4C4"
          fill-opacity="0.75"
        />
      </svg>
    </div>
    <div class="filler"></div>
    <ul>
      <li><a href="https://montanacorporate.com/">Home</a></li>
      <li><a href="https://montanacorporate.com/licensing">Licensing</a></li>
      <li><a href="https://montanacorporate.com/formllcfaq">Form an LLC</a></li>
      <li><a href="https://montanacorporate.com/llcservices">Services</a></li>
      <li><a href="https://montanacorporate.com/fees">Fees</a></li>
      <li><a href="https://montanacorporate.com/testimonials">Testimonials</a></li>
      <li><a href="https://montanacorporate.com/support">Help & Support</a></li>
      <li><a href="https://montanacorporate.com/contact">Contact Us</a></li>
    </ul>
    <div class="hamburger">
      <div @click="toggleNavIcon" id="nav-icon" ref="navIcon">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div id="menu" ref="menu">
        <ul>
          <li><a href="https://montanacorporate.com/">Home</a></li>
          <li><a href="https://montanacorporate.com/licensing">Licensing</a></li>
          <li><a href="https://montanacorporate.com/formllcfaq">Form an LLC</a></li>
          <li><a href="https://montanacorporate.com/llcservices">Services</a></li>
          <li><a href="https://montanacorporate.com/fees">Fees</a></li>
          <li><a href="https://montanacorporate.com/testimonials">Testimonials</a></li>
          <li><a href="https://montanacorporate.com/support">Help & Support</a></li>
          <li><a href="https://montanacorporate.com/contact">Contact Us</a></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import $ from 'jquery';
export default {
  name: 'Header',
  methods: {
    toggleNavIcon() {
      if (this.$refs.navIcon.classList.contains('open')) {
        this.$refs.navIcon.classList.remove('open');
        this.$refs.menu.classList.remove('open');
        $(document).unbind('scroll');
        $('body').css({ overflow: 'visible' });
      } else {
        this.$refs.navIcon.classList.add('open');
        this.$refs.menu.classList.add('open');
        window.scrollTo(0, 0);
        $(document).bind('scroll', function () {
          window.scrollTo(0, 0);
        });
        $('body').css({ overflow: 'hidden' });
      }
    },
  },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: #c4c4c4bf;
  height: 100px;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  -o-transition: background 0.2s;
  transition: background 0.2s;
  z-index: 5;
}

html:not([data-scroll='0']) header {
  background: #c4c4c4;
}

html:not([data-scroll='0']) .logo img {
  width: 150px;
}

html:not([data-scroll='0']) .logo-bkg-short,
html:not([data-scroll='0']) .logo-bkg {
  opacity: 0;
}

@media only screen and (max-width: 1408px) {
  header {
    height: 130px;
  }
  .logo-bkg {
    display: none;
  }
  .logo-bkg-short {
    display: block !important;
  }
  html:not([data-scroll='0']) .logo img {
    width: 195px;
    margin-right: 85px;
  }
}

@media only screen and (max-width: 550px) {
  .logo-bkg-short {
    display: none !important;
  }
  .logo img {
    margin-top: 20px;
    margin-left: 25px !important;
    width: 140px !important;
  }
}

header ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-decoration: none;
  list-style-type: none;
  margin-top: 45px;
  margin-right: 20px;
}

@media only screen and (max-width: 939px) {
  header > ul {
    display: none;
  }
}

header li a {
  padding: 5px 25px;
  color: #2d2d2d;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none !important;
}

header li a:hover {
  color: black;
}

.filler {
  flex: 1;
}

.logo {
  z-index: 10;
}

.logo img {
  margin-left: 25px;
  width: 280px;
  -webkit-transition: width 0.2s, margin 0.2s;
  -moz-transition: width 0.2s, margin 0.2s;
  -o-transition: width 0.2s, margin 0.2s;
  transition: width 0.2s, margin 0.2s;
}

.logo svg {
  position: absolute;
  left: 0px;
  z-index: -1;
}

.logo-bkg {
  top: 100px;
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.logo-bkg-short {
  display: none;
  top: 130px;
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

/* Hamburger Menu */

.hamburger {
  display: none;
  margin-right: 45px;
}

@media only screen and (max-width: 939px) {
  .hamburger {
    display: initial;
  }
}

#nav-icon {
  width: 60px;
  height: 45px;
  position: relative;
  padding: 0;
  margin: 40px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 100;
}

#nav-icon span {
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: #2d2d2d;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 18px;
}

#nav-icon span:nth-child(4) {
  top: 36px;
}

#nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 1px;
  height: 1px;
  z-index: 50;
  background: #c4c4c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-transition: width 400ms ease, height 400ms ease;
  -moz-transition: width 400ms ease, height 400ms ease;
  -o-transition: width 400ms ease, height 400ms ease;
  transition: width 400ms ease, height 400ms ease;
}

#menu.open {
  width: 100%;
  height: 100%;
}

#menu ul {
  flex-direction: column;
  text-align: center;
  display: flex;
  padding: 0px;
  margin: 0px;
  opacity: 0;
  z-index: -50;
  -webkit-transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  -o-transition: opacity 200ms;
  transition: opacity 200ms;
  pointer-events: none;
}

#menu.open ul {
  -webkit-transition: opacity 500ms 400ms;
  -moz-transition: opacity 500ms 400ms;
  -o-transition: opacity 500ms 400ms;
  transition: opacity 500ms 400ms;
  opacity: 1;
  z-index: 50;
  pointer-events: all;
}

#menu ul li a {
  display: block;
  font-size: 30px;
  padding: 17px;
}
</style>
