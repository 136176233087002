<template>
  <div class="body">
    <div class="container" v-if="!complete">
      <img src="../assets/logo.svg" alt="" />
      <h1>Reset Password</h1>
      <input v-model="email" @keydown.enter="resetPassword()" type="text" placeholder="Email" />
      <span class="errorMsg">{{ errorMsg }}</span>
      <button :class="{ loading: isLoading }" @click="resetPassword()"><span>Reset Password</span><img src="../assets/spinner.svg" alt="" /></button>
      <span class="login" @click="goToLogin()">Login</span>
    </div>
    <div class="container" v-if="complete">
      <img src="../assets/logo.svg" alt="" />
      <h1>Reset Password</h1>
      <p>An email has been sent with a link to reset the password.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reset Password',
  props: ['passedEmail', 'passedPassword', 'GLBURL'],
  data() {
    return {
      email: '',
      errorMsg: '',
      isLoading: false,
      complete: false,
    };
  },
  methods: {
    goToLogin() {
      this.$emit('updateCredential', this.email, 'passedEmail');
      this.$router.push({ name: 'login' });
    },
    resetPassword() {
      if (!this.email) {
        this.errorMsg = 'Please fill out all the fields.';
        return;
      }
      this.isLoading = true;
      this.errorMsg = '';

      fetch(`${this.GLBURL}/generatepwreset`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ uname: this.email }),
      })
        .then((res) => {
          if (!res.ok) {
            throw res.status;
          }
          return res.json();
        })
        .then((data) => {
          this.isLoading = false;
          if (!data.success) throw data.error;
          this.complete = true;
        })
        .catch((err) => {
          console.log(err);
          if (err == 'no account') {
            this.errorMsg = 'No account found with that email.';
          } else {
            this.errorMsg = 'Something went wrong. Please try again.';
          }
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.email = this.passedEmail;
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 130px);
  .container {
    width: 100%;
    max-width: 22rem;
    border: 1px solid #dcdcdc;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 1.5rem;
    }
    img {
      width: 12rem;
    }
    input {
      border: 1px solid #dcdcdc;
      padding: 0.8rem;
      margin: 0.5rem 0;
    }
    button {
      border: none;
      color: white;
      background: #eb9002;
      padding: 0.9rem;
      margin: 1rem 0;
      cursor: pointer;
      img {
        display: none;
      }
    }
    .loading {
      padding: 0.306rem;
      img {
        display: inline;
        width: 2rem;
      }
      span {
        display: none;
      }
    }
    .errorMsg {
      margin-bottom: -1rem;
      font-size: 0.8rem;
      height: 1.1rem;
      color: red;
      text-align: center;
    }
    .login {
      text-align: center;
      color: #585858;
      font-size: 0.8rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
