<template>
  <div class="body">
    <div class="container" v-if="!complete && !errorMsg">
      <img src="../assets/logo.svg" alt="" />
      <h1>Verifing Account</h1>
      <p>Please wait.</p>
    </div>
    <div class="container" v-else-if="errorMsg">
      <img src="../assets/logo.svg" alt="" />
      <h1>Account Verification Failed</h1>
      <p>{{ errorMsg }}</p>
      <button @click="verifyAccount()">Try Again</button>
    </div>
    <div class="container" v-if="complete">
      <img src="../assets/logo.svg" alt="" />
      <h1>Account Verfied!</h1>
      <button @click="goToLogin()">Login</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Verify',
  props: ['GLBURL'],
  data() {
    return {
      errorMsg: '',
      complete: false,
    };
  },
  methods: {
    verifyAccount() {
      this.errorMsg = '';

      fetch(`${this.GLBURL}/verifyaccount`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ uname: this.$route.query.username, key: this.$route.query.key }),
      })
        .then((res) => {
          if (!res.ok) {
            throw res.status;
          }
          return res.json();
        })
        .then((data) => {
          if (!data.success) throw data.error;
          this.complete = true;
        })
        .catch((error) => {
          console.log(error);
          this.errorMsg = 'There was an error verifying your account.';
        });
    },
    goToLogin() {
      this.$emit('updateCredential', this.$route.query.username, 'passedEmail');
      this.$router.push({ name: 'login' });
    },
  },
  mounted() {
    this.verifyAccount();
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 130px);
  .container {
    width: 100%;
    max-width: 22rem;
    border: 1px solid #dcdcdc;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 1.5rem;
    }
    img {
      width: 12rem;
    }
    input {
      border: 1px solid #dcdcdc;
      padding: 0.8rem;
      margin: 0.5rem 0;
    }
    button {
      border: none;
      color: white;
      background: #eb9002;
      padding: 0.9rem;
      margin: 1rem 0;
      cursor: pointer;
      img {
        display: none;
      }
    }
  }
}
</style>
