import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import Verify from '../views/Verify.vue';
import ResetPassword from '../views/ResetPassword.vue';
import CreatePassword from '../views/CreatePassword.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
  },
  {
    path: '/verify',
    name: 'verify',
    component: Verify,
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: ResetPassword,
  },
  {
    path: '/createpassword',
    name: 'createpassword',
    component: CreatePassword,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
