<template>
  <Header />
  <router-view @updateCredential="(newCred, credType) => (this[credType] = newCred)" :GLBURL="GLBURL" :passedEmail="passedEmail" :passedPassword="passedPassword" />
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
  data() {
    return {
      passedEmail: '',
      passedPassword: '',
      //GLBURL: 'http://localhost:3127/portal',
      GLBURL: 'https://portal.montanacorporate.com/portal',
    };
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
}
</style>
